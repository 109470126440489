<template>
  <div class="panel panel-default">
    <div class="panel-heading">全国土壤管理 > 编辑土壤数据</div>
    <div class="panel-body">
      <div class="table-responsive input-form col-sm-3">
        <p><b>基本信息</b></p>
        <form action="" @submit.prevent="handleSubmit">
          <ul>
            <li>
              <label for="">省ID:</label
              ><input type="text" v-model="province_id" />
            </li>
            <li>
              <label for="">县ID:</label><input type="text" v-model="site_id" />
            </li>
            <li>
              <label for="">省名称:</label
              ><input type="text" v-model="province" />
            </li>
            <li>
              <label for="">县名称:</label
              ><input type="text" v-model="county" />
            </li>
            <li>
              <label for="">地点ID:</label
              ><input type="text" v-model="location_id" />
            </li>
            <li>
              <label for="">采样地点:</label
              ><input type="text" v-model="location" />
            </li>
            <li>
              <label for="">标本类型:</label
              ><input type="text" v-model="specimens_type" />
            </li>
            <li>
              <label for="">俗名:</label
              ><input type="text" v-model="local_soil_name" />
            </li>
            <li>
              <label for="">发生分类土类:</label
              ><input type="text" v-model="csgc_group" />
            </li>
            <li>
              <label for="">发生分类土纲:</label
              ><input type="text" v-model="csgc_order" />
            </li>
            <li>
              <label for="">调查人:</label
              ><input type="text" v-model="survey_staff" />
            </li>
            <li>
              <label for="">野外调查日期:</label
              ><input type="text" v-model="survey_date" />
            </li>
            <li>
              <label for="">标本提供者:</label
              ><input type="text" v-model="provider" />
            </li>
            <li>
              <label for="">剖面标本管理者:</label
              ><input type="text" v-model="profile_manager" />
            </li>
            <li>
              <label for="">标本制作日期:</label
              ><input type="text" v-model="profile_monolith_date" />
            </li>
            <li>
              <label for="">储存柜号:</label
              ><input type="text" v-model="storage_item" />
            </li>
            <li>
              <label for="">储存屉号:</label
              ><input type="text" v-model="storage_row" />
            </li>
            <li>
              <label for="">剖面深度记录:</label
              ><input type="text" v-model="profile_depth_rec" />
            </li>
            <li>
              <label for="">标本保存地点:</label
              ><input type="text" v-model="profile_monolith_location" />
            </li>
            <li>
              <label for="">分类鉴别单位:</label
              ><input type="text" v-model="charge_enterprise" />
            </li>
            <li>
              <label for="">剖面ID:</label
              ><input type="text" v-model="profile_id" disabled />
            </li>
            <li>
              <label for="">景观照片链接:</label
              ><input type="text" v-model="landscape_photo" />
            </li>
            <li>
              <label for="">剖面照片链接:</label
              ><input type="text" v-model="profile_photo" />
            </li>

            <li>
              <input type="submit" class="btn btn-default" value="提交" />
            </li>
          </ul>
        </form>
      </div>
      <div class="table-responsive input-form col-sm-9">
        <!-- 剖面生境调查 -->
        <div>
          <p><b>剖面生境调查</b></p>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr class="th">
                  <!-- <th>剖面ID</th> -->
                  <th>景观ID</th>
                  <th>经度</th>
                  <th>纬度</th>
                  <th>高程(m)</th>
                  <th>母质</th>
                  <th>年均温(℃)</th>
                  <th>年降水(mm)</th>
                  <th>无霜期(天)</th>
                  <th>土地利用</th>
                  <th>人类活动</th>
                  <th>植被</th>
                  <th>优势种</th>
                  <th>地形</th>
                  <th>土地覆盖</th>
                  <th>坡度</th>
                  <th>坡位</th>
                  <th>侵蚀类型</th>
                  <th>侵蚀强度</th>
                  <th>地表草皮覆盖</th>
                  <th>土壤水份状况</th>
                  <th>土壤温度状况</th>
                  <th>一般性描述</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <!-- <td><input type="text" v-model="profile_id" /></td> -->
                  <td><input type="text" v-model="landscape_id" /></td>
                  <td><input type="text" v-model="longitude" /></td>
                  <td><input type="text" v-model="latitude" /></td>
                  <td><input type="text" v-model="elevation" /></td>
                  <td><input type="text" v-model="parent_material" /></td>
                  <td>
                    <input type="text" v-model="mean_annual_temperature" />
                  </td>
                  <td><input type="text" v-model="mean_annual_rainfall" /></td>
                  <td><input type="text" v-model="frost_free_period" /></td>
                  <td><input type="text" v-model="landuse" /></td>
                  <td><input type="text" v-model="human_activities" /></td>
                  <td><input type="text" v-model="VEGETATION" /></td>
                  <td><input type="text" v-model="SPECIES" /></td>
                  <td><input type="text" v-model="TOPOGRAPHY" /></td>
                  <td><input type="text" v-model="LANDFORM" /></td>
                  <td><input type="text" v-model="SLOPE_GRADIENT" /></td>
                  <td><input type="text" v-model="SLOPE_ASPECT" /></td>
                  <td><input type="text" v-model="EROSION_TYPE" /></td>
                  <td><input type="text" v-model="EROSION_INTENSITY" /></td>
                  <td><input type="text" v-model="GRASS_COVER" /></td>
                  <td><input type="text" v-model="SOIL_MOISTURE" /></td>
                  <td><input type="text" v-model="SOIL_TEMPERATURE" /></td>
                  <td><input type="text" v-model="GENERAL_DESCRIPTION" /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <input
            type="submit"
            class="btn btn-default my-submit"
            value="提交"
            @click="handleProfileHabitatSubmit"
          />
        </div>

        <br />

        <!-- 发生层形态调查 -->
        <div v-if="LayerMorphologyList.length">
          <p><b>发生层形态调查</b></p>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr class="th">
                  <th>层次ID</th>
                  <th>层次代码</th>
                  <th>深度 (cm)</th>
                  <th>剖面描述</th>
                  <th>剖面颜色</th>
                  <th>发生层质地</th>
                  <th>结构</th>
                  <th>发生层孔隙度</th>
                  <th>发生层界面过渡</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in LayerMorphologyList" :key="index">
                  <td>
                    <input type="text" v-model="item.horizon_id" disabled />
                  </td>
                  <td><input type="text" v-model="item.horizon_code" /></td>
                  <td>
                    <input type="text" v-model="item.horizon_begin_depth" />
                  </td>
                  <td><input type="text" v-model="item.description" /></td>
                  <td><input type="text" v-model="item.horizon_color" /></td>
                  <td><input type="text" v-model="item.horizon_texture" /></td>
                  <td>
                    <input type="text" v-model="item.horizon_structure" />
                  </td>
                  <td><input type="text" v-model="item.horizon_porosity" /></td>
                  <td><input type="text" v-model="item.horizon_boundary" /></td>
                </tr>
              </tbody>
            </table>
          </div>
          <input
            type="submit"
            class="btn btn-default my-submit"
            value="提交"
            @click="handleLayerMorphologySubmit"
          />
        </div>

        <br v-if="LayerMorphologyList.length" />

        <!-- 发生层理化分析 -->
        <div v-if="LayerPhysicochemicalList.length">
          <p><b>发生层理化分析 (非必填)</b></p>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr class="th">
                  <th>分析ID</th>
                  <th>层次代码</th>
                  <th>深度 (cm)</th>
                  <th>pH (H2O)</th>
                  <th>有机碳 (g/kg)</th>
                  <th>全氮 (g/kg)</th>
                  <th>粘粒含量 (%)</th>
                  <th>粉粒含量 (%)</th>
                  <th>砂粒含量 (%)</th>
                  <th>质地</th>
                  <th>阳离子交换量 (cmol/kg)</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in LayerPhysicochemicalList"
                  :key="index"
                >
                  <td>
                    <input type="text" v-model="item.analysis_id" disabled />
                  </td>
                  <td><input type="text" v-model="item.horizon_code" /></td>
                  <td><input type="text" v-model="item.horizon_depth" /></td>
                  <td><input type="text" v-model="item.ph_h2o" /></td>
                  <td><input type="text" v-model="item.total_c" /></td>
                  <td><input type="text" v-model="item.total_n" /></td>
                  <td><input type="text" v-model="item.horizon_clay" /></td>
                  <td><input type="text" v-model="item.HORIZON_SILT" /></td>
                  <td><input type="text" v-model="item.HORIZON_SAND" /></td>
                  <td><input type="text" v-model="item.HORIZON_TEXTURE" /></td>
                  <td>
                    <input
                      type="text"
                      v-model="item.CATION_EXCHANGE_CAPACITY"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <input
            type="submit"
            class="btn btn-default my-submit"
            value="提交"
            @click="handleLayerPhysicochemicalSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import qs from "qs";

export default {
  data() {
    return {
      // 表单校验
      errors: [],
      // 基本信息
      provice_id: "",
      province_id: "",
      site_id: "",
      province: "",
      county: "",
      location_id: "",
      location: "",
      specimens_type: "",
      local_soil_name: "",
      csgc_group: "",
      csgc_order: "",
      survey_staff: "",
      survey_date: "",
      provider: "",
      profile_manager: "",
      profile_monolith_date: "",
      storage_item: "",
      storage_row: "",
      profile_depth_rec: "",
      profile_monolith_location: "",
      charge_enterprise: "",
      profile_id: "",
      landscape_photo: "",
      profile_photo: "",
      // 剖面生境调查
      landscape_id: "",
      longitude: "",
      latitude: "",
      elevation: "",
      parent_material: "",
      mean_annual_temperature: "",
      mean_annual_rainfall: "",
      frost_free_period: "",
      landuse: "",
      human_activities: "",
      VEGETATION: "",
      SPECIES: "",
      TOPOGRAPHY: "",
      LANDFORM: "",
      SLOPE_GRADIENT: "",
      SLOPE_ASPECT: "",
      EROSION_TYPE: "",
      EROSION_INTENSITY: "",
      GRASS_COVER: "",
      SOIL_MOISTURE: "",
      SOIL_TEMPERATURE: "",
      GENERAL_DESCRIPTION: "",
      // 发生层形态调查
      LayerMorphologyList: [],
      // 发生层理化分析
      LayerPhysicochemicalList: [],
    };
  },
  mounted() {
    // 加载从上一个页面传入的基本信息
    const item = JSON.parse(this.$route.query.item);
    // console.log(item);
    Object.keys(item).forEach((key) => {
      //   console.log(key, this[key], item[key])
      this[key] = item[key];
    });
    this.province_id = this.provice_id;

    // 请求剖面生境调查信息
    axios
      .get(`./dataProfileHabitat/get?profile_id=${this.profile_id}`)
      .then((res) => {
        const profileHabitatData = res.data.data[0];
        Object.keys(profileHabitatData).forEach((key) => {
          this[key] = profileHabitatData[key];
        });
      });

    // 请求发生层形态调查信息
    axios
      .get(`./dataLayerMorphology/get?profile_id=${this.profile_id}`)
      .then((res) => {
        this.LayerMorphologyList = res.data.data;
      });

    // 请求发生层理化分析信息
    axios
      .get(`./dataLayerPhysicochemical/get?profile_id=${this.profile_id}`)
      .then((res) => {
        this.LayerPhysicochemicalList = res.data.data;
      });
  },
  methods: {
    handleSubmit() {
      const {
        province_id,
        site_id,
        province,
        county,
        location_id,
        location,
        specimens_type,
        local_soil_name,
        csgc_group,
        csgc_order,
        survey_staff,
        survey_date,
        provider,
        profile_manager,
        profile_monolith_date,
        storage_item,
        storage_row,
        profile_depth_rec,
        profile_monolith_location,
        charge_enterprise,
        profile_id,
        landscape_photo,
        profile_photo,
      } = this;

      let baseData = {
        province_id,
        site_id,
        province,
        county,
        location_id,
        location,
        specimens_type,
        local_soil_name,
        csgc_group,
        csgc_order,
        survey_staff,
        survey_date,
        provider,
        profile_manager,
        profile_monolith_date,
        storage_item,
        storage_row,
        profile_depth_rec,
        profile_monolith_location,
        charge_enterprise,
        profile_id,
        landscape_photo,
        profile_photo,
      };

      // 表单校验
      this.errors = [];

      if (!this.province_id) {
        this.errors.push("必填省ID;");
      }
      if (!this.site_id) {
        this.errors.push("必填县ID;");
      }
      if (!this.profile_id) {
        this.errors.push("必填剖面ID;");
      }

      if (this.province_id && this.site_id && this.profile_id) {
        axios
          .post(`./data/updateProfileBaseMessage `, qs.stringify(baseData))
          .then((res) => {
            if (res.data.code != 200) {
              this.$message.error("操作失败, 请检查服务器");
              return;
            }
            // 更新数据
            this.$store.dispatch("getChinaListAction");
            this.$message({
              message: "更新土壤数据成功",
              type: "success",
            });
          });
      }
    },
    handleProfileHabitatSubmit() {
      const {
        profile_id,
        landscape_id,
        longitude,
        latitude,
        elevation,
        parent_material,
        mean_annual_temperature,
        mean_annual_rainfall,
        frost_free_period,
        landuse,
        human_activities,
        VEGETATION,
        SPECIES,
        TOPOGRAPHY,
        LANDFORM,
        SLOPE_GRADIENT,
        SLOPE_ASPECT,
        EROSION_TYPE,
        EROSION_INTENSITY,
        GRASS_COVER,
        SOIL_MOISTURE,
        SOIL_TEMPERATURE,
        GENERAL_DESCRIPTION,
      } = this;

      let profileHabitatData = {
        profile_id,
        landscape_id,
        longitude,
        latitude,
        elevation,
        parent_material,
        mean_annual_temperature,
        mean_annual_rainfall,
        frost_free_period,
        landuse,
        human_activities,
        VEGETATION,
        SPECIES,
        TOPOGRAPHY,
        LANDFORM,
        SLOPE_GRADIENT,
        SLOPE_ASPECT,
        EROSION_TYPE,
        EROSION_INTENSITY,
        GRASS_COVER,
        SOIL_MOISTURE,
        SOIL_TEMPERATURE,
        GENERAL_DESCRIPTION,
      };

      // console.log(profileHabitatData);

      axios
        .post(`./dataProfileHabitat/update `, qs.stringify(profileHabitatData)) // 剖面生境调查
        .then((res) => {
          if (res.data.code != 200) {
            this.$message.error("操作失败, 请检查服务器");
            return;
          }
          this.$message({
            message: "更新土壤数据成功",
            type: "success",
          });
        });
    },
    handleLayerMorphologySubmit() {
      if (this.LayerMorphologyList.length == 0) return;

      let promiseArr = [];

      for (let i = 0; i < this.LayerMorphologyList.length; i++) {
        const element = this.LayerMorphologyList[i];
        promiseArr.push(
          new Promise((resolve, reject) => {
            axios
              .post(`/dataLayerMorphology/update`, qs.stringify(element))
              .then((res) => {
                if (res.data.code != 200) reject();
                resolve();
              });
          })
        );
      }

      Promise.all([promiseArr])
        .then(() => {
          this.$message({
            message: "更新土壤数据成功",
            type: "success",
          });
        })
        .catch(() => {
          this.$message.error("操作失败, 请检查服务器");
        });
    },
    handleLayerPhysicochemicalSubmit() {
      if (this.LayerPhysicochemicalList.length == 0) return;

      let promiseArr = [];

      for (let i = 0; i < this.LayerPhysicochemicalList.length; i++) {
        const element = this.LayerPhysicochemicalList[i];
        promiseArr.push(
          new Promise((resolve, reject) => {
            axios
              .post(`/dataLayerPhysicochemical/update`, qs.stringify(element))
              .then((res) => {
                if (res.data.code != 200) reject();
                resolve();
              });
          })
        );
      }

      Promise.all([promiseArr])
        .then(() => {
          this.$message({
            message: "更新土壤数据成功",
            type: "success",
          });
        })
        .catch(() => {
          this.$message.error("操作失败, 请检查服务器");
        });
    },
  },
};
</script>

<style>
.table {
  margin-bottom: 0px;
}
.my-submit {
  margin-top: 7px;
}
.input-form li input[type="text"] {
  width: 200px;
}
table input{
  width: 120px;
}
</style>